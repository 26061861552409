var windowURL = new URL(window.location.href.toLowerCase());
var appURL = null;
var passportURL = null;
var apiURL = null;
var env = null;

if (windowURL.hostname.indexOf('localhost') !== -1) {
  appURL = `${windowURL.protocol}//localhost:3000`;
  passportURL = `${windowURL.protocol}//localhost:3001`;
  apiURL = `${windowURL.protocol}//localhost:4000`;
  env = 'local';
} else if (windowURL.hostname.indexOf('testing') !== -1) {
  appURL = `${windowURL.protocol}//app-${windowURL.hostname}`;
  passportURL = `${windowURL.protocol}//passport-${windowURL.hostname}`;
  apiURL = `${windowURL.protocol}//api-${windowURL.hostname}`;
  env = 'testing';
} else {
  appURL = `${windowURL.protocol}//app.smilecloud.com`;
  passportURL = `${windowURL.protocol}//passport.smilecloud.com`;
  apiURL = `${windowURL.protocol}//api.smilecloud.com`;
  env = 'production';
}

const ENV = env;
const APP_URL = appURL;
const API_URL = apiURL;
const LOGIN_URL = appURL + '/login';
const PASSPORT_LOGIN_URL = passportURL + '/login';
const REGISTER_URL = appURL + '/register';
const PASSPORT_REGISTER_URL = passportURL + '/register';
const PATIENT_REGISTER_URL = PASSPORT_REGISTER_URL;
const DENTIST_REGISTER_URL = REGISTER_URL + '/dentist';
const TEAM_REGISTER_URL = REGISTER_URL + '/team';

const LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'Deutsch' },
  { value: 'ro', label: 'Română' },
  { value: 'es', label: 'Español' },
  { value: 'pt', label: 'Portugues' },
  { value: 'fr', label: 'Français' },
  { value: 'tr', label: 'Türkçe' },
  { value: 'it', label: 'Italiano' }
];

const TEXTS = {
  pricing: 'pricing',
  blog: 'blog',
  login: 'login',
  register: 'register',
  joinToday: 'joinToday',
  terms: 'terms',
  privacy: 'privacy',
  cookiesPolicy: 'cookiesPolicy',
  cookiesUsePolicy: 'cookiesUsePolicy',
  subprocessors: 'subprocessors',
  contactUs: 'contactUs',
  connect: 'connect',
  startTrial: 'startTrial',
  natural: 'natural',
  download: {
    link: 'download.link',
    title: 'download.title',
    description: 'download.description'
  }
};

const findGetParameter = parameterName => {
  let result = null,
    tmp = [];

  window.location.search
    .substr(1)
    .split('&')
    .forEach(function(item) {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });

  return result !== 'null' ? result : null;
};

module.exports = {
  ENV,
  APP_URL,
  API_URL,
  LOGIN_URL,
  PASSPORT_LOGIN_URL,
  REGISTER_URL,
  PASSPORT_REGISTER_URL,
  PATIENT_REGISTER_URL,
  DENTIST_REGISTER_URL,
  TEAM_REGISTER_URL,
  TEXTS,
  LANGUAGES,
  findGetParameter
};
