import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PriceCard from './PriceCard';
import { useFeatures } from '../features/useFeatures';
import PricingButton from '../shared/button/PricingButton';
import { DENTIST_REGISTER_URL } from '../../common/constants';
import './priceCards.scss';
import IntervalRadio from '../interval/IntervalRadio';
import { API_URL } from '../../common/constants';

const DEFAULT_PRICES = {
  // TODO: currently we default to EUR as this is the only currency, but we'll need to change this to USD after the release of multi-currency
  currency: 'EUR',
  starter: {
    annual: 20,
    monthly: 25
  },
  business: {
    annual: 30,
    monthly: 35
  }
};

const users = {
  starter: 1,
  business: 2,
  enterprise: 50
};

const PriceCards = ({ interval, onChangeInterval, isMultiCurrencyEnabled }) => {
  const { t, i18n } = useTranslation();
  const features = useFeatures();
  const billedAnnually = interval === 'annually';
  const containerRef = useRef();
  const [pixelsFromTop, setPixelsFromTop] = useState();
  const [prices, setPrices] = useState(null);

  const currency = prices?.currency;

  useEffect(() => {
    if (isMultiCurrencyEnabled) {
      getPlans()
        .then(prices => setPrices(prices))
        .catch(() => setPrices(DEFAULT_PRICES));
    } else {
      setPrices(DEFAULT_PRICES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const containerRect = containerRef.current?.getBoundingClientRect();
      setPixelsFromTop(containerRect?.top);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const TrialButton = (
    <PricingButton
      fullWidth
      variant="primary"
      label={t('v2.pricing.subscription.trialDays')}
      onClick={() => (window.location.href = DENTIST_REGISTER_URL)}
    />
  );

  const ContactButton = (
    <PricingButton
      fullWidth
      variant="outlined"
      label={t('v2.pricing.subscription.contactUs')}
      onClick={() => window.zE?.activate()}
    />
  );

  const starterPrice = getPlanPrice('starter');
  const businessPrice = getPlanPrice('business');

  return (
    <>
      <IntervalRadio
        value={interval}
        onChange={onChangeInterval}
        cardsPixelsFromTop={pixelsFromTop}
      />
      <article className="pricing__subscriptions" ref={containerRef}>
        <PriceCard
          title="Starter"
          subtitle={t('v2.pricing.subscriptions.starter.subtitle')}
          price={
            starterPrice
              ? formatCurrency(starterPrice, i18n.language, currency)
              : null
          }
          pricePeriod={`/${t('common.month')}`}
          billedAnnually={billedAnnually}
          users={`${users.starter} ${t('common.user', {
            count: users.starter
          })}`}
          cases={`120 ${t('common.case', { count: 120 })}`}
          features={
            billedAnnually
              ? features.starter.annually
              : features.starter.monthly
          }
          button={TrialButton}
        />

        <PriceCard
          title="Business"
          subtitle={t('v2.pricing.subscriptions.business.subtitle')}
          price={
            businessPrice
              ? formatCurrency(businessPrice, i18n.language, currency)
              : null
          }
          pricePeriod={`/${t('common.user')} /${t('common.month')}`}
          billedAnnually={billedAnnually}
          users={`2+ ${t('common.user', { count: 2 })}`}
          unlimitedCases
          featuresTitle={t('v2.pricing.starterFeaturesAnd')}
          features={features.business}
          button={TrialButton}
          transparent
          mostPopular={!billedAnnually}
        />

        <PriceCard
          title="Enterprise"
          subtitle={t('v2.pricing.subscriptions.enterprise.subtitle')}
          price="Custom"
          pricePeriod={`/${t('common.user')} /${t('common.month')}`}
          billedAnnually={billedAnnually}
          users={`50+ ${t('common.user', { count: 50 })}`}
          unlimitedCases
          featuresTitle={t('v2.pricing.businessFeaturesAnd')}
          features={features.enterprise}
          button={ContactButton}
        />
      </article>
    </>
  );

  /**
   * @param {"starter" | "business"} planType
   * @returns {null | number}
   */
  function getPlanPrice(planType) {
    if (!prices) {
      return null;
    }

    const plan = prices[planType];

    if (!plan) {
      return null;
    }

    return billedAnnually ? plan.annual : plan.monthly;
  }
};

/**
 *
 * @param {number} value
 * @param {string} lang
 * @param {string} currency
 * @returns {string}
 */
function formatCurrency(value, lang, currency = DEFAULT_PRICES.currency) {
  return new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(value);
}

/**
 * @returns {Promise<Array<unknown>>}
 */
async function getPlans() {
  const response = await fetch(`${API_URL}/api/public/plans-addons`);
  const data = await response.json();
  return getPriceInfoFromPlans(data.plans);
}

/**
 * @param {Array<unknown>} plans
 * @returns {{ currency: string, starter: { annual: number, monthly: number }, business: { annual: number, monthly: number } }}
 */
function getPriceInfoFromPlans(plans) {
  const starterMonthly = plans.filter(p => p.type === 5 && p.cycle === 1)[0];
  const starterAnnually = plans.filter(p => p.type === 5 && p.cycle === 2)[0];
  const businessMonthly = plans.filter(p => p.type === 6 && p.cycle === 1)[0];
  const businessAnnually = plans.filter(p => p.type === 6 && p.cycle === 2)[0];

  if (
    !starterMonthly ||
    !starterAnnually ||
    !businessMonthly ||
    !businessAnnually
  ) {
    return DEFAULT_PRICES;
  }

  return {
    starter: {
      monthly: getPricePerMonth(starterMonthly),
      annual: getPricePerMonth(starterAnnually)
    },
    business: {
      monthly: getPricePerMonth(businessMonthly),
      annual: getPricePerMonth(businessAnnually)
    },
    currency: starterMonthly.currency || DEFAULT_PRICES.currency
  };
}

function getPricePerMonth(plan) {
  const MONTHS_PER_YEAR = 12;
  const pricePerUser = plan.price / plan.includedTeamSize;

  if (plan.cycle === 1) {
    return pricePerUser;
  }

  return pricePerUser / MONTHS_PER_YEAR;
}

PriceCards.propTypes = {
  interval: PropTypes.oneOf(['annually', 'monthly']),
  onChangeInterval: PropTypes.func,
  isMultiCurrencyEnabled: PropTypes.bool
};

export default PriceCards;
