import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGES, findGetParameter } from './common/constants';

import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

let lang = (
  (navigator.languages && navigator.languages[0]) ||
  navigator.userLanguage ||
  navigator.language
)
  .replace('-', '_')
  .toLowerCase()
  .split('_')[0];
lang = findGetParameter('lang') || lang;
const langsArr = LANGUAGES.map(lang => lang.value);
const browserLanguage = langsArr.indexOf(lang) === -1 ? 'en' : lang;

console.log('PROCESS ENV: ', process.env);

const phraseLocaleId = {
  de: 'a08ec6c2b5439681db4bcfb05e1ee31b',
  en: 'faa111e3003c446f8cec247d21d6238a',
  es: 'f3464f8fe3d9e538d73ffe90b932dfbe',
  fr: '9140d8b5624738e9f6d914e3af3a53fa',
  pt: 'daca9aa3811c8b737e07cabdfd578c8d',
  ro: '46d6f62eb6360f0821313e4d3f958954',
  tr: '0e520099422a01521d6c768fb9e0ec47',
  it: 'f5cf4c9c9e56d968e013c52d16845257'
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: browserLanguage,
    fallbackLng: 'en',
    debug: true,
    react: { useSuspense: true },
    returnObjects: true,

    backend: {
      // for all available options read the backend's repository readme file
      //   loadPath: `${API_URL}/locales/www/{{lng}}/{{ns}}.json`,
      loadPath: function(lngs, namespaces) {
        return `https://api.phrase.com/v2/projects/9e8e8f3a15e12402db96a3fa50d89957/locales/${
          phraseLocaleId[lngs[0]]
        }/download?file_format=i18next`;
      },
      crossDomain: true,
      customHeaders: {
        authorization: 'token ' + process.env.REACT_APP_PHRASE_TOKEN,
        'Cache-Control': 'no-cache'
      }
    },

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
